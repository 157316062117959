import { Unsubscribe } from "firebase/auth";
import { query, collection, where, getDocs, doc, updateDoc, arrayRemove, arrayUnion, onSnapshot } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../utils/firebase";
import styles from './allmemes.module.css';

type VideoStatus = {
    id: string,
    name: string,
    url: string,
    employee: string,
    location: string,
    likeCount: number,
    selfLike: boolean
};

const AllMemes: FC = () => {
    const [name, setName] = useState<string>('');
    const [videos, setVideos] = useState<VideoStatus[]>([]);
    const navigate = useNavigate();
    let unsub: Unsubscribe | null = null;
    
    useEffect(() => {
        const tempID = localStorage.getItem("empID") || '';
        if (tempID === '') {
            navigate('/');
        }
        getVideos(tempID);

        return () => {
            if (unsub !== null) {
                unsub();
            }
        }
    }, []);

    const getVideos = async (empID: string) => {
        const qEmp = query(collection(db, 'users'), where("employeeID", "==", empID));
        const qEmpSnap = await getDocs(qEmp);
        qEmpSnap.forEach((doc) => setName(doc.data().employeeName as string));

        const q = query(collection(db, 'memes'), where("empID", "!=", ""));
        unsub = onSnapshot(q, async (querySnap) => {
            const tempVideos: VideoStatus[] = [];
            for (let i = 0; i < querySnap.size; i++) {
                const doc = querySnap.docs[i];
                const name = (doc.data().memePath as string).split('/')[1];
                const likes = doc.data().likes as string[];
                const selfLike = likes.findIndex((val) => val === empID) !== -1;
                const likeCount = likes.length;
                
                const tempID = doc.data().empID as string;
    
                const qAll = query(collection(db, 'users'), where('employeeID', '!=', ''));
                const snapAll = await getDocs(qAll);
                const userData: { id: string, name: string, location: string }[] = [];
                snapAll.forEach((doc) => userData.push({ id: doc.data().employeeID, name: doc.data().employeeName, location: doc.data().location }));
                const employeeData = userData.find((val) => val.id === tempID);
    
                const storageRef = ref(storage, doc.data().memePath);
                try {
                    const url = await getDownloadURL(storageRef);
                    tempVideos.push({ id: doc.id, name, url, employee: employeeData?.name || '', location: employeeData?.location || '', likeCount, selfLike });
                }
                catch (err) {
                    console.error('Error getting video: ' + err);
                }
            }
            setVideos(tempVideos);
        });
    }

    const toggleLike = async (id: string, liked: boolean) => {
        const tempID = localStorage.getItem("empID") || '';
        if (tempID === '') {
            navigate('/');
        }

        const docRef = doc(db, "memes", id);
        if (liked) {
            await updateDoc(docRef, {
                likes: arrayRemove(tempID)
            });
        }
        else {
            await updateDoc(docRef, {
                likes: arrayUnion(tempID)
            });
        }
    }

    return (
        <>
        <div style={{ padding: '6px', textAlign: 'center', fontSize: '3vh', color: 'black' }}>
            <p>Welcome {name}</p>
        </div>

        <div className={styles.responsive}>
            {/* <div className={styles.gallery}> */}
                {
                    videos.map((video, vidIndex) =>
                        <div key={'allmemes' + vidIndex.toString()} className={styles.gallery}>
                            <div className={styles.desc}>{"Created by " + video.employee + ", " + video.location}</div>
                            <a target='_blank' href={video.url} rel="noreferrer">
                                <video src={video.url} height="auto" controls style={{ maxWidth: '100%' }} />
                            </a>
                            <div className={styles.likebuttondiv}>
                                <button onClick={()=>toggleLike(video.id, video.selfLike)}>{video.selfLike ? "UnLike" : "Like"}</button>
                            </div>
                            <div className={styles.likecount}>
                                {
                                    video.likeCount === 0
                                    &&
                                    "No one liked it"
                                }
                                {
                                    video.likeCount > 0 && video.selfLike
                                    &&
                                    ("You " + ((video.likeCount - 1) > 0 ? ("and " + (video.likeCount - 1).toString() + " other person liked it") : ("liked it")))
                                }
                                {
                                    video.likeCount > 0 && !video.selfLike
                                    &&
                                    (video.likeCount.toString() + " people liked it")
                                }
                            </div>
                        </div>
                    )
                }
            {/* </div> */}

            {/* <div className="gallery">
                <div className="desc">
                </div>
                <a target="_blank" href="" id="">
                    <video src="" width="100%" height="auto" controls />
                </a>
                <div className="likebuttondiv">
                    <a href="">
                    </a>
                </div>
                <div className="likecount">
                </div>
            </div> */}
        </div>
        
        <div className="clearfix"></div>

        <div style={{ padding: '6px', opacity: 0 }}>
            <p>Thank you.</p>
        </div>
        </>
    );
}

export default AllMemes;