import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../utils/firebase";

const Upload: FC = () => {
    const maxSize = 20;
    const [empID, setEmpID] = useState<string>('');
    const [filesToUpload, setFiles] = useState<FileList | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const tempID = localStorage.getItem('empID') || '';
        setEmpID(tempID);
        getEmployeeEmail(tempID);
        if (tempID === '') {
            navigate('/', { replace: true });
        }
    }, []);

    const getEmployeeEmail = async (empID: string) => {
        const q = query(collection(db, "users"), where("employeeID", "==", empID));
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            localStorage.setItem("empEmail", doc.data().email as string);
            localStorage.setItem("empName", doc.data().employeeName as string);
        });
    }

    const upload = async () => {
        if (filesToUpload === null) {
            return alert('Please upload some files');
        }

        for (let i = 0; i < filesToUpload.length; i++) {
            const file = filesToUpload[i];
            if ((file.size / (1024 * 1024)) > maxSize) {
                alert('Max size exceeded for file: ' + file.name);
                continue;
            }
            const fileName = empID + '/' + file.name;
            const storageRef = ref(storage, fileName);
            uploadBytes(storageRef, file).then(() => {
                console.log('File ' + file.name + ' uploaded');
                if (i === filesToUpload.length - 1) {
                    alert('Files uploaded');
                    navigate('/thank-you');
                }
            }).catch((err) => {
                console.error(err);
            });

            try {
                await addDoc(collection(db, 'memes'), {
                    empID, memePath: fileName, likes: []
                });
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    const seeMyMemes = () => {
        navigate('/my-memes');
    }

    const seeAllMemes = () => {
        navigate('/all-memes');
    }

    return (
        <>
        <div id="content">
            <div className="imageContainer">
                <div id="warning">(Reel Video should be in .mp4 or .wav or format with file size should not exceed {maxSize} MB)</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <input type="file" name="files[]" style={{ fontSize: '2vh', color: 'white' }} multiple accept=".mp4, .wav" onChange={(ev)=>setFiles(ev.target.files)} />
                    <div id="uploadButton">
                        <button type="button" name="submit" style={{ fontSize: '3vh' }} onClick={upload}>UPLOAD</button>
                    </div>
                </div>
                <div id="seeMyMemes">
                    <button style={{ fontSize: '2.5vh' }} name="MemePageButton" onClick={seeMyMemes}>SEE MY REELS</button>
                </div>
                <div id="seeAllMemes">
                    <button style={{ fontSize: '2.5vh' }} name="MemeRatingPageButton" onClick={seeAllMemes}>SEE ALL REELS</button>
                </div>
            </div>
        </div>
        </>
    );
}

export default Upload;