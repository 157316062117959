import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ThankYou: FC = () => {
    const navigate = useNavigate();
    // const oldURL = 'https://script.google.com/macros/s/AKfycbwSQvDpVIAS5W4Fg_SVbWwDo6Z8DPDa2PCxV6Ji-SnPNACVkt1ZMflbCpLEBiEopWBeDA/exec';
    // const email = "noreply@redchariots.com";
    const emailToSendTo = localStorage.getItem("empEmail") || '';

    useEffect(() => {
        sendEmail();
    }, []);

    const sendEmail = async () => {
        const emailScriptURL = 'https://script.google.com/macros/s/AKfycbyVzr6ZkSS0TOsNoA0ydyxVCC5DXiRXTohCap8xA5OCO3Xx0TVyxR_83XzkG738iFLr2Q/exec';
        const nameOfPerson = localStorage.getItem("empName");
        if (emailToSendTo !== null && nameOfPerson !== null) {
            const url = emailScriptURL + '?' + 'destination=' + emailToSendTo + '&employee=' + nameOfPerson;
            const result = await fetch(url, { method: 'GET' });
            console.log(await result.text());
        }
    }

    return (
        <div className="imageContainer">
           <div className="form-container">
                <div style={{ position: 'absolute', top: '50vh', width: '100%', textAlign: 'center', color: 'white', fontSize: '3vh', fontWeight: 'bold' }}>
                Your Entry is Registered Successfully!
                <br />
                Thank You for your Participation
                </div>
                <div style={{ position: 'absolute', top: '65vh', widows: '100%', textAlign: 'center', color: 'white', fontSize: '2vh', fontWeight: 'bold' }}>
                    {
                        true
                        &&
                        ("Email successfully sent to " + emailToSendTo)
                    }
                    {
                        false
                        &&
                        ("Cannot send email to " + emailToSendTo + ". Something went wrong.")
                    }
                </div>
                {/* <div style={{ position: 'absolute', top: '75vh', width: '100%', textAlign: 'center', color: 'white', fontSize: '2vh', fontWeight: 'bold'}}>
                    <button style={{fontSize: "2.5vh"}} name="MemePageButton" onClick={()=>navigate('/my-memes')}>SEE MY MEMES</button>
                </div> */}
                <div style={{ position: 'absolute', top: '83vh', width: '100%', textAlign: 'center', color: 'white', fontSize: '2vh', fontWeight: 'bold'}}>
                    <button style={{fontSize: "2.5vh"}} name="HomeButton" onClick={()=>navigate('/upload')}>HOME</button>
                </div>
           </div>
        </div>
    );
}

export default ThankYou;