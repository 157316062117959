import { collection, getDocs, query, where } from "firebase/firestore";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/firebase";

const Home: FC = () => {
    const [empID, setEmpID] = useState<string>('');
    const navigate = useNavigate();

    const login = async () => {
        if (empID === '') {
            return alert('Please fill in the employee ID');
        }

        localStorage.setItem('empID', empID);

        const q = query(collection(db, 'users'), where('employeeID', '==', empID));
        const querySnap = await getDocs(q);
        if (querySnap.size > 0) {
            navigate('/upload');
        }
        else {
            navigate('/register');
        }
    }

    return (
        <div className="imageContainer">
            <div className="form-container">
                <div style={{ fontSize: '3vh', fontWeight: 'bold', color: 'white' }}>ENTER EMPLOYEE ID</div>
                <input
                    type="text" style={{ textTransform: 'uppercase', fontSize: '3vh', marginTop: '5vh' }} name="emp_id" value={empID} required
                    onChange={(ev)=>setEmpID(ev.target.value)}
                />
                <div>
                    <button type="button" name="submit" style={{ marginTop: '6vh', fontSize: '3vh' }} onClick={login}>ENTER</button>
                </div>
            </div>
        </div>
    );
}

export default Home;