import { collection, getDocs, query, where } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../utils/firebase";

const MyMemes: FC = () => {
    const [videos, setVideos] = useState<{name: string, url: string}[]>([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const tempID = localStorage.getItem("empID") || '';
        if (tempID === '') {
            navigate('/');
        }
        getVideos(tempID);
    }, []);

    const getVideos = async (empID: string) => {
        console.log(empID);
        const q = query(collection(db, 'memes'), where("empID", "==", empID));
        const querySnap = await getDocs(q);

        const tempVideos: { name: string, url: string }[] = [];
        for (let i = 0; i < querySnap.size; i++) {
            const doc = querySnap.docs[i];
            const name = (doc.data().memePath as string).split('/')[1];
            const storageRef = ref(storage, doc.data().memePath);
            try {
                const url = await getDownloadURL(storageRef);
                tempVideos.push({ name, url });
            }
            catch (err) {
                console.error('Error getting video: ' + err);
            }
        }
        setVideos(tempVideos);
    }

    return (
        <>
        <div style={{ padding: '6px', textAlign: 'center', fontSize: '3vh' }}>
            <p>YOUR MEMES</p>
        </div>

        <div className="responsive">
            {/* <div className="gallery"> */}
                {
                    videos.map((video, vidIndex) =>
                        <div key={'yourmemes' + vidIndex.toString()} className="gallery">
                            <a target='_blank' href={video.url} rel="noreferrer">
                                <video src={video.url} height="auto" controls style={{ maxWidth: '100%' }} />
                            </a>
                            <div className="desc">{video.name}</div>
                        </div>
                    )
                }
            {/* </div> */}
        </div>

        <div className="clearfix"></div>

        <div style={{ padding: '6px', opacity: 0 }}>
            <p>You will learn more about media queries and responsive web design later in our CSS Tutorial.</p>
        </div>
        </>
    );
}

export default MyMemes;