import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDp8sZafFBXpVrLX85AYc2Ntxn9ejQfQh8",
    authDomain: "smiles-ap-memes.firebaseapp.com",
    projectId: "smiles-ap-memes",
    storageBucket: "smiles-ap-memes.appspot.com",
    messagingSenderId: "890570536022",
    appId: "1:890570536022:web:93faea60cf6bb42dba37f7",
    measurementId: "G-5B4FCR38DC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };