import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AllMemes from "./pages/AllMemes";
import Home from "./pages/Home";
import MyMemes from "./pages/MyMemes";
import Register from "./pages/Register";
import ThankYou from "./pages/ThankYou";
import Upload from "./pages/Upload";
import { auth } from "./utils/firebase";

const email = 'redchariots@gmail.com';
const password = 'rc_ap_memes_#v#nt';

const App: FC = () => {
    const [user, setUser] = useState<UserCredential | undefined>();

    useEffect(() => {
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                setUser(user);
            })
            .catch((error) => {
                console.error(error);
            });
        })
        .catch((error) => {
            console.error(error);
        });

        auth.onAuthStateChanged((user) => {
            if (user !== null) {
                console.log('User signed in!');
            }
        })
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/register" element={<Register />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/my-memes" element={<MyMemes />} />
            <Route path="/all-memes" element={<AllMemes />} />
        </Routes>
    );
}

export default App;
