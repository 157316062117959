import { addDoc, collection } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/firebase";
import styles from "./register.module.css";

const Register: FC = () => {
    const employeeID = localStorage.getItem('empID') || '';
    const [email, setEmail] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [department, setDept] = useState<string>('');
    const [employeeName, setName] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (employeeID === '') {
            navigate('/');
        }
    }, [employeeID]);

    const registerEmployee = async () => {
        const user = {
            employeeID, employeeName, email, mobile, department, location
        };
        try {
            await addDoc(collection(db, 'users'), user);
            navigate('/upload');
        }
        catch (err) {
            alert('Something went wrong. Please try again.');
            navigate('/');
        }
    }

    return (
        <div className={styles.imageContainer}>
           <div className={styles.formContainer}>
                <div style={{ position: 'absolute', fontSize: '2vh', color: 'white', fontWeight: 'bold' }} className={styles.container}>
                    <div className={styles.inputContainer}>
                        <label>NAME:</label>
                        <input 
                            type = "text" name="emp_name" id="name" required style={{ textTransform: 'uppercase', fontSize: '2.5vh', marginLeft: '6vh' }}
                            value={employeeName} onChange={(ev)=>setName(ev.target.value)}
                        />
                    </div>          
                    <br />
                    <div className={styles.inputContainer}>
                        <label>OFFICIAL EMAIL ID:</label>
                        <input
                            type = "email" name="emp_mail" id="email" required style={{ fontSize: '2.5vh', marginLeft: '6vh' }}
                            value={email} onChange={(ev)=>setEmail(ev.target.value)}
                        />
                    </div>  
                    <br />
                    <div className={styles.inputContainer}>
                        <label>MOBILE NUMBER:</label>
                        <input
                            type = "text" name="emp_mobilenum" id="mobile" required style={{ fontSize: '2.5vh', marginLeft: '6vh' }}
                            value={mobile} onChange={(ev)=>setMobile(ev.target.value)}
                        />
                    </div>  
                    <br />
                    <div className={styles.inputContainer}>
                        <label>DEPARTMENT:</label>                    
                        <input
                            type = "text" name="emp_department" id="department" required style={{ fontSize: '2.5vh', marginLeft: '6vh' }}
                            value={department} onChange={(ev)=>setDept(ev.target.value)}
                        />
                    </div>  
                    <br />
                    <div className={styles.inputContainer}>
                        <label>LOCATION:</label>                    
                        <select
                            id="location" name="emp_location"required style={{ fontSize: '2.5vh', marginLeft: '6vh' }}
                            value={location} onChange={(ev)=>setLocation(ev.target.options[ev.target.selectedIndex].value)}
                        >
                            <option value="AP Infinity Plant">AP Infinity Plant</option>
                            <option value="AP Color Protect 2">AP Color Protect 2</option>
                            <option value="AP Creators">AP Creators</option>
                            <option value="Penta">Penta</option>
                            <option value="AP Infinity(Sales & DM offices)">AP Infinity(Sales & DM offices)</option>
                            <option value="AP Infinity(Sales Depots)">AP Infinity(Sales Depots)</option>
                            <option value="AP Living HI">AP Living HI</option>
                            <option value="AP Wizards TL">AP Wizards TL</option>
                            <option value="AP Global">AP Global</option>
                            <option value="AP Core Offices">AP Core Offices</option>
                        </select>
                    </div>  
                    
                    <div className={styles.button} style={{ position: 'absolute', top: '45vh', width: '100%', textAlign: 'center' }}>
                        <button type="button" onClick={registerEmployee} name="enter" style={{ fontSize: '3vh' }}>ENTER</button>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Register;